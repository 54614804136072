import React from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Box, Paper, Stack,Typography,useMediaQuery} from "@mui/material";
import LogModalItem from './LogModalItem';
import formatDate from 'app/utils/formatDate';

const ModalLogs = ({ item, getDadosGravados }) => {
    const {theme} = useJumboTheme();
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);


    
  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
        {lg && <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>}
        <Paper sx={{ p: '40px', height: '500px' }}>
            <Box sx={{ height: '100%', overflowY: 'auto' }}>
                <Typography variant={"h1"} style={{ textAlign: 'center', marginBottom: 30 }}>
                    Detalhes do log
                </Typography>

                {/* Seção de Alterações Simples */}
                {item.alteracoes && Object.keys(item.alteracoes).length > 0 && (
                    <Box mt={2}>
                        <Typography variant="h2" mb={2}>Alterações Realizadas</Typography>
                        {Object.entries(item.alteracoes).map(([campo, valores], index) => {
                            const campoFormatado = campo === 'createdAt' ? 'Criado em' : campo === 'updatedAt' ? 'Atualizado em' : campo;
                            const antesFormatado = campo === 'createdAt' || campo === 'updatedAt' ? formatDate(valores.antes, true) : valores.antes;
                            const depoisFormatado = campo === 'createdAt' || campo === 'updatedAt' ? formatDate(valores.depois, true) : valores.depois;
                            return (
                                <Box key={index} mb={2}>
                                    <Typography variant="h6"><strong>{campoFormatado}</strong></Typography>
                                    <Typography variant="body1">Antes: {antesFormatado}</Typography>
                                    <Typography variant="body1">Depois: {depoisFormatado}</Typography>
                                </Box>
                            );
                        })}
                        {item?.User?.nome && (
                            <Typography variant="body1" mt={2}>
                                <strong>Modificado por:</strong> {item.User?.nome}
                            </Typography>
                        )}
                    </Box>
                )}

                {/* Separação visual */}
                <Box mt={4} mb={2}>
                    <Typography variant="h2" style={{ borderBottom: '1px solid #ddd', paddingBottom: '5px' }}>
                        Alterações Técnicas
                    </Typography>
                </Box>

                {/* Seção de Alterações Técnicas */}
                <React.Fragment>
                    {item.dados_antigo !== null
                        ? <LogModalItem itemName={'Dados enviados'} itemType={item.dados_antigo} />
                        : <Typography variant={'h6'}>Nenhum histórico de dados enviados encontrado</Typography>}
                    {item.dados_gravados !== null
                        ? <LogModalItem itemName={'Resposta recebida'} itemType={getDadosGravados(item)} />
                        : <Typography variant={'h6'}>Nenhum histórico de resposta recebida encontrado</Typography>}
                </React.Fragment>
            </Box>
        </Paper>
    </JumboContentLayout>
  )
}

export default ModalLogs