import React, { useState, useCallback, useEffect } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useLocation } from 'react-router-dom';
import CourseApprovalList from 'app/components/CourseApprovalList/CourseApprovalList';
import ContinuingCoursesList from 'app/components/ContinuingCoursesList/ContinuingCoursesList';
import CurrentCoursesAccordion from 'app/components/CurrentCoursesAccordion/CurrentCoursesAccordion';
import { CourseWithAllStatusList } from 'app/components/CourseWithAllStatusList/CourseWithAllStatusList';
import CompatibleCoursesList from 'app/components/CompatibleCoursesList/CompatibleCoursesList';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { GreatVibesRegular } from '../../../../assets/fonts/GreatVibes-regular';
import { capitalizeFLetter } from '@jumbo/utils';

const ModalCourseHistory = ({ user, username, userId = null, handleClose }) => {
  const { theme } = useJumboTheme();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  window.pdfMake.vfs['GreatVibes-Regular.ttf'] = GreatVibesRegular;
  pdfMake.fonts = {
    Roboto: {
      normal:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
    GreatVibes: {
      normal: 'GreatVibes-Regular.ttf',
      bold: 'GreatVibes-Regular.ttf', // Se não houver versão 'bold', você pode usar a mesma
      italics: 'GreatVibes-Regular.ttf',
      bolditalics: 'GreatVibes-Regular.ttf',
    },
  };

  console.log(pdfMake);

  console.log(user);

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const { id } = useParams();
  const location = useLocation();

  const [courseHistory, setCourseHistory] = useState([]);
  const [courseHistoryOnlyApproval, setCourseHistoryOnlyApproval] = useState(
    []
  );
  const [courseHistoryFeespOnlyApproval, setCourseHistoryFeespOnlyApproval] =
    useState([]);
  const [courseHistoryOnlyActive, setCourseHistoryOnlyActive] = useState([]);
  const [coursesCompatible, setCoursesCompatible] = useState([]);
  const [coursesContinued, setCoursesContinued] = useState([]);
  const [modalTitle, setModalTitle] = useState('Histórico de Cursos');
  const [modalSubtitle, setModalSubtitle] = useState();
  const [activeComponent, setActiveComponent] = useState('initial');
  const [director, setDirector] = useState([]);

  const currentPath = location.pathname;
  const inClassesURL =
    currentPath.startsWith('/app/editar-turma') ||
    currentPath.startsWith('/app/listar-cursos-outras-entidades') ||
    currentPath.startsWith('/app/listar-curso-lote') ||
    currentPath.startsWith('/app/listar-consultar-historico') ||
    currentPath.startsWith('/app/listar-avaliacao-de-documentos') ||
    currentPath.startsWith('/app/meus-dados');

  const idSelected = inClassesURL ? userId : id;

  const getCourseHistory = useCallback(async () => {
    try {
      ApiService.get(`/historico-cursos-feesp-outras-entidades/${idSelected}`)
        .then((response) => {
          console.log(response.data);
          setCourseHistory(response.data.historico_cursos);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourseHistoryOnlyFeespApproved = useCallback(async () => {
    try {
      ApiService.get(`/historico-cursos-feesp/${idSelected}?approved=${true}`)
        .then((response) => {
          console.log(response.data);
          setDirector(response.data?.diretor);
          setCourseHistoryFeespOnlyApproval(response.data?.historico_cursos);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourseHistoryWithApproval = useCallback(async () => {
    try {
      console.log('id selecionado', idSelected);
      ApiService.get(`/cursos-regulares-ativos/${idSelected}`)
        .then((response) => {
          console.log('sdsds', response);
          setCourseHistoryOnlyActive(response.data.cursosRegularesAtivos);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCoursesRegular = useCallback(async () => {
    try {
      ApiService.get(`/cursos-regulares-continuados/${idSelected}`)
        .then((response) => {
          console.log(
            'redlkfjd',
            response.data?.historicoCursosRegulares?.historicoCursos
          );
          /* setCoursesCompatible(
            response.data?.historicoCursosRegulares?.historicoCursos
          ); */
          setCourseHistoryOnlyApproval(
            response.data?.historicoCursosRegulares?.historicoCursos
          );
          setCoursesContinued(
            response.data?.historicoCursosContinuados?.historicoCursos
          );
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCourseHistoryFromSpiritualCenter = useCallback(async () => {
    try {
      ApiService.get(`/centro-espirita/historico-usuario/${idSelected}`)
        .then((response) => {
          console.log('cursos centro espirita compativeis', response.data);
          setCoursesCompatible(response.data.cursoHistoricoDoUsuario);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleBackClick = () => {
    setActiveComponent('initial');
    setModalTitle('Histórico de Cursos');
    setModalSubtitle('');
  };

  const handleCompatibleCoursesClick = () => {
    setActiveComponent('compatible');
    setModalTitle('Histórico de Cursos Compatibilizados');
    setModalSubtitle('');
  };

  const handleDetailedHistoryClick = () => {
    setActiveComponent('detailedHistory');
    setModalTitle('Histórico Detalhado de Cursos');
    setModalSubtitle(
      'Somente constam dados de cursos realizados a partir de 2024'
    );
  };

  const renderHistoryButton = () => {
    return (
      <>
        {activeComponent === 'initial' && (
          <>
            <Button
              size='small'
              variant='contained'
              color='primary'
              style={{ marginRight: 10 }}
              onClick={handleCompatibleCoursesClick}
            >
              Cursos Compatibilizados
            </Button>
            <Button
              size='small'
              variant='contained'
              color='primary'
              onClick={handleDetailedHistoryClick}
            >
              Histórico Detalhado
            </Button>
          </>
        )}
      </>
    );
  };

  const renderContent = () => {
    switch (activeComponent) {
      case 'compatible':
        return <CompatibleCoursesList coursesCompatible={coursesCompatible} />;
      case 'detailedHistory':
        return (
          <CourseWithAllStatusList courseHistoryOnlyApproval={courseHistory} />
        );
      default:
        return (
          <>
            <CurrentCoursesAccordion
              courseHistoryOnlyActive={courseHistoryOnlyActive}
            />
            <CourseApprovalList
              courseHistoryOnlyApproval={courseHistoryOnlyApproval}
              user={user}
              director={director}
            />
            <ContinuingCoursesList coursesContinued={coursesContinued} />
          </>
        );
    }
  };

  useEffect(() => {
    if (id || userId) {
      getCourseHistory();
      getCoursesRegular();
      getCourseHistoryWithApproval();
      getCourseHistoryFromSpiritualCenter();
      getCourseHistoryOnlyFeespApproved();
    }
  }, []);

  const loadImageBase64 = (path) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', path);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  const generatePDF = (historico, pessoa, diretor) => {
    Promise.all([
      loadImageBase64(
        `${process.env.REACT_APP_API_KEY}/images/${diretor?.assinatura}`
      ), // Assinatura
      loadImageBase64('/images/logositefeesp.png'), // Logo
      loadImageBase64('/images/ICONE_AREA_ENSINO.png')
    ]).then(([assinaturaBase64, logoBase64, iconArea]) => {
      const cursos = historico.map((item) => [
        { text: item.ano_de_conclusao ?? '-', alignment: 'center' },
        item.Turma.Curso.nome,
        { text: item.Turma?.Curso?.carga_horaria ?? '-', alignment: 'center' },
      ]);

      const docDefinition = {
        content: [
          {
            columns: [
              {
                image: logoBase64,
                width: 200,
                alignment: 'left',
                margin: [0, 0, 0, 0],
              },
             /*  {
                text: 'ÁREA DE ENSINO',
                alignment: 'right',
                bold: true,
                fontSize: 16,
                margin: [0, 35, 0, 0],
              }, */
              {
                image: iconArea,
                width: 135,
                margin: [180, 25, 0, 0],
              },
            ],
          },
          /* {
            text:
              'São Paulo, ' +
              new Date().toLocaleDateString('pt-BR') +
              '\n(Emitido via sistema às ' +
              new Date().toLocaleTimeString('pt-BR') +
              ')',
            style: 'dataEmissao',
            alignment: 'center',
          }, */
          {
            text: '\n\nDECLARAÇÃO DE CURSOS REALIZADOS\n\n',
            style: 'header',
            alignment: 'center',
          },
          {
            text: [
              'Declaramos, para os devidos fins, que ',
              { text: pessoa.nome, bold: true, fontSize: 14, },
              `, CPF ${pessoa.cpf ?? '-'}, concluiu os seguintes cursos em nossa instituição:`
            ],
            style: 'body',
          },
          {
            table: {
              widths: ['auto', '*', 'auto'],
              body: [
                [
                  { text: 'ANO DE CONCLUSÃO', style: 'tableHeader' },
                  { text: 'CURSO', style: 'tableHeader' },
                  { text: 'CARGA HORÁRIA', style: 'tableHeader' },
                ],
                ...cursos,
              ],
            },
            style: 'table',
          },
          {
            text: '\nColocamo-nos à disposição para quaisquer esclarecimentos.',
            style: 'body',
          },
          {
            text: '\n\nAtenciosamente,\n\n',
            style: 'body',
          },
          {
            alignment: 'center',
            stack: [
              {
                image: assinaturaBase64, // Imagem base64 carregada dinamicamente
                width: 150, // Largura da imagem da assinatura
              },
              { text: '______________________________' },
              { text: `${diretor?.User?.nome}`, bold: true },
              { text: 'Diretor da Área de Ensino' },
              {
                text: `Emitido em ${new Date().toLocaleDateString('pt-BR')}, às ${new Date().toLocaleTimeString('pt-BR')}, via sistema. Dúvidas: entrar em contato com a área de Ensino pelo telefone 011 3188-8383 ramal 237`,
                fontSize: 10,
                marginTop: 10,

              }
            ],
          },
        ],
        styles: {
          header: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 10],
          },
          subheader: {
            fontSize: 12,
            margin: [0, 10, 0, 10],
          },
          body: {
            fontSize: 11,
            margin: [0, 10, 0, 10],
          },
          table: {
            margin: [0, 20, 0, 20],
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
            color: 'black',
            fillColor: '#eeeeee',
          },
          dataEmissao: {
            fontSize: 10,
            italics: true,
            margin: [0, 40, 0, 0],
          },
        },
      };

      pdfMake.createPdf(docDefinition).open();
    });
  };

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px', height: '500px' }}>
        <Box sx={{ height: '100%', overflowY: 'auto' }}>
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            marginRight='1rem'
            gap={1}
          >
            {activeComponent !== 'initial' && (
              <Button
                size='small'
                variant='contained'
                color='success'
                onClick={handleBackClick}
              >
                Voltar
              </Button>
            )}
            <Button size='small' variant='contained' onClick={handleClose}>
              Fechar
            </Button>
            <Button
              size='small'
              variant='contained'
              color='success'
              disabled={!director?.assinatura || courseHistoryFeespOnlyApproval.length === 0}
              onClick={() =>
                generatePDF(courseHistoryFeespOnlyApproval, user, director)
              }
            >
              Declaração de Cursos Realizados
            </Button>
          </Box>
          <Typography
            variant={'h1'}
            style={{ textAlign: 'center', marginBottom: 30 }}
          >
            {modalTitle}
          </Typography>
          {/* <Typography variant={'h5'} fontWeight={'bold'}>
            {modalSubtitle}
          </Typography> */}
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <Typography variant='h4' fontWeight={'300'}>
              Aluno selecionado:
            </Typography>

            <Typography
              variant='h4'
              textTransform={'capitalize'}
              fontWeight={'600'}
            >
              {username}
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='end'
            alignItems='center'
            marginBottom='1rem'
          >
            {renderHistoryButton()}
          </Box>
          <React.Fragment>{renderContent()}</React.Fragment>
        </Box>
      </Paper>
    </JumboContentLayout>
  );
};

export default ModalCourseHistory;
