import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  Box,
  FormLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { updateInputValue, disableInputValue } from '../../utils/appHelpers';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { COUNTRIES } from '../../utils/constants/countries';
import Select from 'react-select';
import CircularProgress from '@mui/material/CircularProgress';
import { cpf } from 'cpf-cnpj-validator';
import { PermissionContext } from 'app/contexts/PermissionContext';
import authServices from 'app/servicesTwo/auth-services';
import Div from '@jumbo/shared/Div';
import { set } from 'date-fns';
import FileListSlim from 'app/components/FileUploadSlim/FileListSlim';
import { formatCPF } from '../login/Login';
import { subMonths, subYears, startOfDay } from 'date-fns';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

const JoinCoursesIntroForm = () => {
  const { hasPermission } = useContext(PermissionContext);

  const { theme } = useJumboTheme();

  const Swal = useSwalWrapper();

  const lg = useMediaQuery(theme.breakpoints.down('md'));

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    nome: null,
    nome_social: null,
    data_nascimento: null,
    genero: '',
    cpf: null,
    rg: null,
    telefone: '05511',
    celular: '05511',
    celular_confirmacao: '',
    documento_internacional: '',
    email: null,
    email_confirmacao: '',
    escolaridade: '',
    religiao: '',
    pais: 'BR',
    bairro: '',
    cidade: '',
    estado: '',
    uf: '',
    numero: '',
    complemento: '',
    CEP: '',
    codigo_postal: '',
    senha: '',
    senhaConfirmacao: '',
    turmas_id: '',
    ficha_compativel: null,
    emailOrCpf: '',
  };

  const { id, turmaId } = useParams();
  const [user, setUser] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [nomeSocialObrigatorio, setNomeSocialObrigatorio] = useState(true);
  const [classes, setClasses] = useState([]);
  const [classesSelected, setClassesSelected] = useState('');
  const [texts, setTexts] = useState('');
  const [confirmedUserData, setConfirmedUserData] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [userHasPassword, setUserHasPassword] = useState(false);
  const [userHasNotPassword, setUserHasNotPassword] = useState(false);
  const [showVerifyButton, setShowVerifyButton] = useState(true);
  const [errorsFromApi, setErrorsFromApi] = useState('');
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [feespStudent, setFeespStudent] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [courseIsClosed, setCourseIsClosed] = useState(false);

  /* const [cpfObrigatorio, setCpfObrigatorio] = useState(false); */

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const [textFile, setTextFile] = useState(null);
  const [backUrl, setBackUrl] = useState('');

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const canCreate = hasPermission('Pessoas', 'criar');
  const canEdit = hasPermission('Pessoas', 'editar');
  let { state } = useLocation();
  const navigate = useNavigate();
  const isDisabled = state && state.disable ? true : id ? !canEdit : !canCreate;

  const [verifyMask, setVerifyMask] = useState(false);

  const celularInputRef = useRef(null);

  useEffect(() => {
    // Se verifyMask for false, focalize o campo de entrada do celular
    if (!verifyMask && celularInputRef.current) {
      celularInputRef.current.focus();
    }
  }, [verifyMask]);

  const validationSchema = yup.object().shape(
    {
      email: yup
        .string()
        .email('Endereço de email inválido')
        .nullable()
        .required('Preenchimento obrigatório'),
      email_confirmacao: yup
        .string()
        .oneOf([yup.ref('email'), null], 'Os emails devem ser iguais')
        .required('Preenchimento obrigatório')
        .nullable(),
      /*  cpf: cpfObrigatorio ? yup
        .string()
        .test({
          message: 'CPF inválido',
          test: (value) => cpf.isValid(value)
        })
        .when(['email', 'rg'], {
          is: (email, rg) => !email && !rg,
          then: yup.string().required('Preenchimento obrigatório').nullable()
        })
        : yup.string().nullable(), */
      cpf: yup
        .string()
        .test({
          message: 'CPF inválido',
          test: (value) => cpf.isValid(value),
        })
        .when(['email', 'rg'], {
          is: (email, rg) => !email && !rg,
          then: yup.string().required('Preenchimento obrigatório').nullable(),
        })
        .nullable(),
      emailOrCpf:
        !isUserVerified && !isNewUser
          ? yup
              .string()
              .required('Campo obrigatório')
              .test('valid-email-or-cpf', 'Email ou CPF inválido', (value) => {
                if (!value) return false;
                // Regex para verificar se é um email válido
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
                // Regex para verificar se é um CPF válido
                const cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
                if (cpfRegex.test(value)) {
                  // Se for um CPF válido, retorna o CPF formatado
                  return formatCPF(value);
                }
                return emailRegex.test(value);
              })
          : yup.string().nullable(),

      nome: !nomeSocialObrigatorio
        ? yup
            .string('Insira seu nome')
            .required('Preenchimento obrigatório')
            .test('full-name', 'Digite ao menos dois nomes', (value) => {
              if (!value) return false;
              return value.trim().split(/\s+/).length >= 2;
            })
            .nullable()
        : yup.string().nullable(),

      data_nascimento: yup
        .date()
        .max(new Date(), 'Não é possivel incluir uma data futura')
        .required('Preenchimento obrigatório')
        .test(
          'idade-minima',
          'O aluno precisa ter pelo menos 18 anos',
          (value) => {
            if (!value) return false;
            // Data mínima: 17 anos e 9 meses atrás
            const hoje = startOfDay(new Date()); // Data atual no início do dia

            const idadeMinima = startOfDay(subMonths(subYears(hoje, 17), 9));
            console.log('Idade mínima permitida:', idadeMinima);

            // Verifica se a data de nascimento é anterior ou igual a idade mínima
            return value <= idadeMinima;
          }
        )
        .nullable(),
      celular: verifyMask
        ? yup
            .string()
            .required('Preenchimento obrigatório')
            .nullable()
            .transform((value) => value?.replace(/[^\d]/g, ''))
            .min(14, 'Preencha o número completo')
            .max(14, 'Preencha o número completo')
        : yup
            .string()
            .required('Preenchimento obrigatório')
            .nullable()
            .transform((value) => value?.replace(/[^\d]/g, '')),

      celular_confirmacao: yup
        .string()
        .required('Preenchimento obrigatório')
        .nullable()
        .transform((value) => value?.replace(/[^\d]/g, ''))
        .test(
          'cell-match',
          'Os números do celular devem ser iguais',
          function (value) {
            return this.parent.celular === value;
          }
        ),
      senha:
        isNewUser || !isUserVerified || !userHasPassword
          ? yup.string().required('Preenchimento obrigatório')
          : yup.string().nullable(),
      senhaConfirmacao:
        isNewUser || !userHasPassword
          ? yup
              .string()
              .required('Preenchimento obrigatório')
              .oneOf([yup.ref('senha')], 'As senhas devem ser iguais')
          : yup.string().nullable(),
    },
    [
      ['email', 'cpf'],
      ['email', 'rg'],
      ['cpf', 'rg'],
    ]
  );

  const toast = (variant, message, timer = 15000, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: timer,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });

    //  Redireciona se mensagem de retorno for de sucesso
    /* if(variant == 'success'){
            setTimeout(function(){
                navigate("/app/listar-usuarios")
            }, 2000)
        } */
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirmation = (event) => {
    event.preventDefault();
  };

  const getCourseIntro = useCallback(async () => {
    try {
      await ApiService.get(`/cursos-introdutorios/${id}`, {
        params: { isOpen: true },
      })
        .then((response) => {
          console.log(response.data.Turma);
          setClasses(response.data.Turma);
          setTexts(response.data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              setCourseIsClosed(true);
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  console.log(files);
  console.log('selecao', filesSelected);

  useEffect(() => {
    if (id) getCourseIntro();
  }, []);

  const handleSubmit = async (
    values,
    { setSubmitting, setFieldError, setStatus }
  ) => {
    if (!confirmedUserData && !isNewUser) {
      await confirmedData(values, setFieldError);
      return;
    }

    const hasRequisites = classes[0]?.Curso?.Curso?.length > 0;
    const pessoaId = values.pessoa_id;
    const newValues = {
      ...values,
      turmas_id: turmaId,
    };

    if (!values.turmas_id) {
      return toast('warning', 'Por favor, selecione um horário');
    }

    const handleSuccess = (title) => {
      Swal.fire({
        title: title,
        text: 'Deseja realizar outras inscrições?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          if (backUrl || state?.backUrl) {
            navigate('/lista-de-cursos');
          } else {
            window.location.reload();
            /* navigate(`/lista-de-cursos?id=${id}`); */
          }
          setDisableSubmitButton(false);
        } else {
          setDisableSubmitButton(true);
          setConfirmedUserData(true);
          window.location.href = 'https://www.feesp.com.br/';
        }
      });
    };

    if (hasRequisites && !user.atendeRequisitos) {
      /*  if (filesSelected.length === 0) {
        return toast('warning', 'Por favor, envie os documentos');
      } */

      Swal.fire({
        title:
          'Confirme que você está ciente de que este curso possui requisitos obrigatórios. Se você não anexou os comprovantes, poderá fazê-lo através do e-mail ensino@feesp.org.br. Qualquer dúvida, utilize-se deste mesmo e-mail.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Não!',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          createData();
        }
      });

      const createData = async () => {
        let formData = new FormData();
        files?.forEach((file, index) => {
          if (file instanceof File) {
            formData.append('docs', file);
            formData.append(`nome_label_${index}`, file.nome_label);
          }
        });

        await ApiService.post(
          `cursos-introdutorios-com-requisitos/${id}/pessoa`,
          values,
          {
            params: { pessoaId: pessoaId },
          }
        )
          .then((response) => {
            /* toast(
              'success',
              user.atendeRequisitos
                ? 'Inscrito com sucesso. Verifique a confirmação no seu email.'
                : 'A inscrição está em análise frente aos comprovantes enviados'
            ); */

            handleSuccess(
              user.atendeRequisitos
                ? 'Inscrito com sucesso. Verifique a confirmação no seu email.'
                : 'A inscrição está em análise frente aos comprovantes enviados'
            );

            console.log('a responsat', response.data);
            const curso_introdutorio_historico_id =
              response?.data?.addPessoaNoCursoIntrodutorio
                ?.curso_introdutorio_historico_id;
            if (files.length > 0) {
              formData.append(
                'curso_introdutorio_historico_id',
                curso_introdutorio_historico_id
              );

              ApiService.post(`/documentos-do-candidato/upload`, formData)
                .then(() => {})
                .catch((error) => {
                  if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('error', error.message);
                  }
                });
            }

            /*  setTimeout(() => {
              window.location.href = 'https://www.feesp.com.br/';
            }, 5000); */
          })
          .catch((error) => {
            const target = error.response.data.error;
            console.log(error.response.data);
            setErrorsFromApi(target);
            toast('error', target);
            //let message = 'Ocorreu um erro'

            if (target.toLowerCase().includes('cpf')) {
              toast('error', 'CPF já cadastrado');
              setFieldError('cpf', 'CPF já cadastrado');
            } else if (target.toLowerCase().includes('rg')) {
              toast('error', 'RG já cadastrado');
              setFieldError('rg', 'RG já cadastrado');
            } else if (target.toLowerCase().includes('email')) {
              toast('error', 'E-mail já cadastrado');
              setFieldError('email', 'E-mail já cadastrado');
            }

            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      };
    } else {
      await ApiService.post(`cursos-introdutorios/${id}/pessoa`, values, {
        params: { pessoaId: pessoaId },
      })
        .then((response) => {
          /* toast(
            'success',
            'Inscrito com sucesso. Verifique a confirmação no seu email.'
          ); */

          handleSuccess(
            'Inscrito com sucesso. Verifique a confirmação no seu email.'
          );
          /*  setTimeout(() => {
            window.location.href = 'https://www.feesp.com.br/';
          }, 5000); */
        })
        .catch((error) => {
          const target = error.response.data.error;
          console.log(error.response.data);
          setErrorsFromApi(target);
          toast('error', target);
          //let message = 'Ocorreu um erro'

          if (target.toLowerCase().includes('cpf')) {
            toast('error', 'CPF já cadastrado');
            setFieldError('cpf', 'CPF já cadastrado');
          } else if (target.toLowerCase().includes('rg')) {
            toast('error', 'RG já cadastrado');
            setFieldError('rg', 'RG já cadastrado');
          } else if (target.toLowerCase().includes('email')) {
            toast('error', 'E-mail já cadastrado');
            setFieldError('email', 'E-mail já cadastrado');
          }

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const makePostRequest = () => {
    const fromLocalStorage = localStorage.getItem('userPendent');
    const parseItems = JSON.parse(fromLocalStorage);
    const valuesToSave = { ...parseItems };
    delete valuesToSave.turmas_id;

    const pessoaId = parseItems.pessoa_id;
    ApiService.post(`cursos-introdutorios/${id}/pessoa`, valuesToSave, {
      params: { pessoaId: pessoaId },
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        const target = error.response.data.error;
        console.log(error.response.data);
        setErrorsFromApi(target);
        toast('error', target);
        //let message = 'Ocorreu um erro'

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  /* if (isUserVerified) {

        setInterval(() => {
            makePostRequest()
        }, 30000);
    } */

  const checkCep = async (e, setFieldValue) => {
    setIsLoading(true);
    const { value } = e.target;

    const cep = value.replace(/\D/g, '');
    await axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        console.log(res.data);
        setFieldValue('endereco', res.data.logradouro);
        // setFieldValue('complemento', res.data.complemento)
        setFieldValue('bairro', res.data.bairro);
        setFieldValue('cidade', res.data.localidade);
        setFieldValue('uf', res.data.uf);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const errorsHandler = (errors) => {
    if (Object.keys(errors).length != 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (errors.cpf) {
        //toast('error', errors.cpf)
      } else if (errors.data_nascimento) {
        //toast('error', errors.data_nascimento)
      } else if (errors.email) {
        // toast('error', errors.email)
      } else if (errors.email_confirmacao) {
        // toast('error', errors.email_confirmacao)
      } else if (errors.celular) {
        // toast('error', errors.celular)
      } else if (errors.celular_confirmacao) {
        // toast('error', errors.celular_confirmacao)
      } else {
        //toast('error', errors.nome)
      }
    } else {
      return null;
    }
  };

  const verifyValidations = (values) => {
    const brazilDDI = '055';
    const cellFormatted = values.celular?.replace(/[^\d]/g, '');

    if (cellFormatted.startsWith(brazilDDI)) {
      setVerifyMask(true);
    } else {
      setVerifyMask(false);
    }

    if (values.nome_social !== null && values.nome_social !== '') {
      setNomeSocialObrigatorio(true);
    } else {
      setNomeSocialObrigatorio(false);
    }

    /*  if (values.cpf?.replace(/[^\d]/g, '').length > 0) {
      setCpfObrigatorio(true);
    } else {
      setCpfObrigatorio(false);
    } */
  };

  function isValidCpf(value) {
    // Remove formatting and checks if length is exactly 11 (valid CPF length without formatting)
    return value && value.replace(/\D/g, '').length === 11;
  }

  const onSignIn = async (emailOrCpf, senha) => {
    console.log('onSignIn', emailOrCpf, senha);
    if (/\S+@\S+\.\S+/.test(emailOrCpf)) {
      let email = emailOrCpf;
      await authServices
        .verifySenha({ email, senha, id, turmaId })
        .then((data) => {
          console.log(data);
          const { senha, ...userWithoutPassword } = data.user;
          const turmas_id = data.getCursoIntrodutorioHistorico?.turmas_id;

          setUser(userWithoutPassword);

          if (
            userWithoutPassword.pais == null ||
            userWithoutPassword.pais == '' ||
            userWithoutPassword.pais == 'brasil'
          ) {
            setUser({ ...userWithoutPassword, pais: 'BR' });
          }
          if (turmas_id) {
            setUser((prev) => ({ ...prev, turmas_id }));
          }

          setUser({
            ...userWithoutPassword,
            atendeRequisitos: data.atende_requisitos,
          });

          setUserHasPassword(data?.senha);

          setIsUserVerified(true);
        })
        .catch((err) => {
          if (err.response.data.error) {
            toast('error', err.response.data.error);
          } else {
            toast('error', err.response.data);
          }
        });
    } else {
      let cpf = emailOrCpf;
      await authServices
        .verifySenha({ cpf, senha, id, turmaId })
        .then((data) => {
          console.log(data);
          const { senha, ...userWithoutPassword } = data.user;
          const turmas_id = data.getCursoIntrodutorioHistorico?.turmas_id;

          setUser(userWithoutPassword);

          if (
            userWithoutPassword.pais == null ||
            userWithoutPassword.pais == '' ||
            userWithoutPassword.pais == 'brasil'
          ) {
            setUser({ ...userWithoutPassword, pais: 'BR' });
          }
          if (turmas_id) {
            setUser((prev) => ({ ...prev, turmas_id }));
          }

          setUser({
            ...userWithoutPassword,
            atendeRequisitos: data.atende_requisitos,
          });

          /* if (data.statusDoCurso) {
                      Swal.fire({
                          title: 'Identificamos que você participou do curso anterior, mas não obteve nota suficiente para aprovação. Gostaria de se matricular novamente neste curso para melhorar sua nota e alcançar a aprovação?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Sim!',
                          cancelButtonText: 'Não!',
                          reverseButtons: true,
                      }).then((result) => {
                          if (result.isConfirmed) {
                              return setIsUserVerified(true);
                          } else {
                              return setIsUserVerified(false);
                          }
                      });
                      return;
                  }
  
                  Swal.close(); */
          setUserHasPassword(data?.senha);
          setIsUserVerified(true);
        })
        .catch((err) => {
          if (err.response.data.error) {
            toast('error', err.response.data.error);
          } else {
            toast('error', err.response.data);
          }
        });
    }
  };

  const handleChangeImage = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    setUserImage(e.target.files[0]);
  };

  const handleRemoveImage = () => {
    setUser((prevValues) => ({
      ...prevValues,
      ficha_compativel: null,
    }));
    setPreviewImage(null);
    setUserImage(null);
  };

  const uploadHandler = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setFiles([...files, file]);
    setFilesSelected([...filesSelected, file]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const handleSaveClick = (index) => {
    setIsEditingFile(false);
    files.map((file, i) =>
      i == index ? (file.nome_label = textFile) : file.nome_label
    );
    setTextFile('');
  };

  const handleEditClick = (index) => {
    setIsEditingFile({ active: true, index: index });
    setTextFile(
      files
        .filter(
          (file, i) =>
            i === index &&
            (file.nome_label || file.nome || file instanceof File)
        )
        .map((file) => {
          if (file instanceof File && file.nome_label) {
            return file.nome_label;
          }
          return file.nome_label || file.nome || file.name;
        })
    );
  };

  const handleInputChangeFile = (e) => {
    setTextFile(e.target.value);
  };

  const confirmedData = async (values, setFieldError) => {
    const newValues = {
      ...values,
      primeiro_login: false,
    };
    await ApiService.put(`/pessoa/${values.pessoa_id}`, {
      values: newValues,
    })
      .then(() => {
        setConfirmedUserData(true);
      })
      .catch((error) => {
        const target = error.response.data.error;
        setErrorsFromApi(target);
        //toast('error', target)
        //let message = 'Ocorreu um erro'
        if (target.toLowerCase().includes('cpf')) {
          toast('error', 'CPF já existente');
          setFieldError('cpf', 'CPF já existente');
        } else if (target.toLowerCase().includes('rg')) {
          toast('error', 'RG já existente');
          setFieldError('rg', 'RG já existente');
        } else if (target.toLowerCase().includes('email')) {
          toast('error', 'E-mail já cadastrado');
          setFieldError('email', 'E-mail já cadastrado');
        } else if (target.toLowerCase().includes('celular')) {
          console.log('aqui');
          toast('error', 'Celular já cadastrado');
          setFieldError('celular', 'Celular já cadastrado');
        }
        //toast('error', message)
        setConfirmedUserData(false);

        if (error.response.data) {
          console.log(error.response.data.error);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  const daysOfWeekOrder = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo',
  ];

  const sortedClasses = classes.sort((a, b) => {
    // Ordenação por dia da semana
    const dayA = daysOfWeekOrder.indexOf(a.dia_da_semana);
    const dayB = daysOfWeekOrder.indexOf(b.dia_da_semana);

    if (dayA !== dayB) return dayA - dayB;

    // Ordenação por horário
    const timeA = a.horario.split(':').map(Number);
    const timeB = b.horario.split(':').map(Number);

    if (timeA[0] !== timeB[0]) return timeA[0] - timeB[0]; // Horas
    if (timeA[1] !== timeB[1]) return timeA[1] - timeB[1]; // Minutos

    /* // Ordenação por modo (On-line vem antes de Sede Maria Paula)
    return a.modo.localeCompare(b.modo); */

    return 0; // Caso sejam iguais
  });

  // Agrupando as aulas por categoria
  const groupedClasses = {
    'Presencial na SEDE MARIA PAULA': sortedClasses.filter(
      (classe) => classe.local === 'Sede' && classe.modo === 'Presencial'
    ),
    'Presencial na CASA TRANSITÓRIA': sortedClasses.filter(
      (classe) =>
        classe.local === 'Casa Transitória' && classe.modo === 'Presencial'
    ),

    'Presencial na SEDE SANTO AMARO': sortedClasses.filter(
      (classe) =>
        classe.local === 'sede_santo_amaro' && classe.modo === 'Presencial'
    ),

    'Híbrido na SEDE MARIA PAULA': sortedClasses.filter(
      (classe) => classe.local === 'Sede' && classe.modo === 'Hibrido'
    ),

    'Híbrido na CASA TRANSITÓRIA': sortedClasses.filter(
      (classe) =>
        classe.local === 'Casa Transitória' && classe.modo === 'Hibrido'
    ),

    'Híbrido na SEDE SANTO AMARO': sortedClasses.filter(
      (classe) =>
        classe.local === 'sede_santo_amaro' && classe.modo === 'Hibrido'
    ),

    'ON-LINE': sortedClasses.filter((classe) => classe.modo === 'EAD'),
  };

  // Filtrando apenas os grupos com dados
  const filteredGroups = Object.entries(groupedClasses).filter(
    ([_, classes]) => classes.length > 0
  );

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {courseIsClosed ? (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          height={'100vh'}
        >
          <Typography variant='h1' fontSize={'2.5rem'}>
            As inscrições para este curso não estão disponíveis.
          </Typography>
        </Box>
      ) : (
        <Paper sx={{ p: '2rem', background: '#f9f9f9' }}>
          {state && (
            <Button
              color='success'
              type='submit'
              variant='contained'
              size='large'
              sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
              onClick={() => navigate(state.backUrl)}
            >
              Voltar
            </Button>
          )}
          <Box textAlign='center'>
            <div dangerouslySetInnerHTML={{ __html: texts?.titulo }} />
            <div dangerouslySetInnerHTML={{ __html: texts?.subtitulo }} />
          </Box>
          <Box textAlign='left'>
            <div dangerouslySetInnerHTML={{ __html: texts?.descricao }} />
          </Box>
          <Formik
            initialValues={user}
            // validationSchema={null}
            validationSchema={validationSchema}
            enableReinitialize
            validateOnChange={false}
            //validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              setFieldValue,
              touched,
              errors,
              setTouched,
              setFieldTouched,
              setFieldError,
            }) => (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container alignContent={'center'}>
                  <Grid
                    container
                    spacing={3}
                    alignContent={'center'}
                    sx={{
                      mt: '0.8rem',
                      background: '#EFF0F2',
                      marginLeft: 0,
                      padding: '0 24px 24px 0',
                    }}
                  >
                    {!isUserVerified && !isNewUser && (
                      <Grid item xs={12}>
                        <Typography variant='h4'>
                          Se você já tem um cadastro, entre com seu CPF ou
                          e-mail. Entre com a senha{' '}
                          <strong>
                            (se for a primeira vez, sua senha é composta pelos 5
                            primeiros números do CPF ou sua data de nascimento
                            (formato ddmmaaaa, somente números))
                          </strong>
                          . Caso não tenha cadastro,&nbsp;
                          <Tooltip title='clique para se cadastrar' arrow>
                            <Link
                              onClick={() => {
                                setIsNewUser(true);
                                setBackUrl(state?.backUrl);
                                getCourseIntro();
                              }}
                            >
                              clique aqui para se cadastrar
                            </Link>
                          </Tooltip>
                          .&nbsp;Você terá de fazer isto uma única vez.
                        </Typography>
                      </Grid>
                    )}
                    {!isNewUser && !isUserVerified && (
                      <>
                        <Grid item lg={4} xs={12}>
                          <JumboTextField
                            sx={{
                              background: '#ffffff',
                            }}
                            fullWidth
                            name='emailOrCpf'
                            label='Email ou CPF'
                            value={values.emailOrCpf}
                            onChange={(event) => {
                              const { name, value } = event.target;
                              setFieldValue(name, formatCPF(value));
                            }}
                            onBlur={() => setFieldTouched('emailOrCpf', true)}
                          />
                        </Grid>

                        <Grid item lg={4} xs={12}>
                          <JumboTextField
                            fullWidth
                            id='senha'
                            type={showPassword ? 'text' : 'password'}
                            name='senha'
                            value={values.senha}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() =>
                                      setShowPassword((show) => !show)
                                    }
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge='end'
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{
                              shrink: isUserVerified ? true : undefined,
                            }}
                            sx={{
                              background: '#ffffff',
                            }}
                            label='Senha'
                            onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                onSignIn(values.emailOrCpf, values.senha);
                              }
                            }}
                            disabled={isUserVerified}
                          />
                          {!isUserVerified && (
                            <Box padding={1}>
                              <Link to='/esqueci-senha' target='_blank'>
                                Esqueceu a senha? Clique aqui
                              </Link>
                            </Box>
                          )}
                        </Grid>

                        <Grid
                          item
                          lg={4}
                          xs={12}
                          textAlign={{
                            xs: 'center',
                            lg: 'initial',
                          }}
                        >
                          <LoadingButton
                            color='success'
                            variant='contained'
                            size='large'
                            onClick={() =>
                              onSignIn(values.emailOrCpf, values.senha)
                            }
                            sx={{
                              maxWidth: {
                                md: '200px',
                                mt: '1rem',
                              },
                            }}
                            disabled={!values.emailOrCpf || !values.senha}
                          >
                            Enviar
                          </LoadingButton>
                        </Grid>
                      </>
                    )}

                    {(isUserVerified || isNewUser) && (
                      <>
                        {isUserVerified && !confirmedUserData && (
                          <Grid item xs={12}>
                            <Typography variant='h5'>
                              Antes de prosseguir para a escolha da turma,
                              confirme se os dados pessoais exibidos estão
                              corretos. Essa etapa é essencial para garantir que
                              sua matrícula seja registrada de forma precisa.
                              Caso não estejam, aproveite para ajustá-los(Exceto
                              Nome e CPF).
                            </Typography>
                          </Grid>
                        )}
                        <Grid item lg={6} xs={12}>
                          {isUserVerified ? (
                            <JumboTextField
                              fullWidth
                              name='nome'
                              label='Nome'
                              value={updateInputValue(user, values, 'nome')}
                              disabled
                              sx={{
                                background: '#ffffff',
                              }}
                            />
                          ) : (
                            <JumboTextField
                              fullWidth
                              name='nome'
                              label='Nome'
                              value={updateInputValue(user, values, 'nome')}
                              disabled={confirmedUserData}
                              sx={{
                                background: '#ffffff',
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name='nome_social'
                            label='Nome Social'
                            value={updateInputValue(
                              user,
                              values,
                              'nome_social'
                            )}
                            sx={{
                              background: '#ffffff',
                            }}
                            disabled={confirmedUserData}
                          />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <JumboTextField
                            fullWidth
                            name='data_nascimento'
                            type='date'
                            label='Data de Nascimento'
                            value={updateInputValue(
                              user,
                              values,
                              'data_nascimento'
                            )}
                            disabled={confirmedUserData}
                            sx={{
                              background: '#ffffff',
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item lg={4} xs={12}>
                          <JumboTextField
                            fullWidth
                            name='rg'
                            label='RG'
                            value={updateInputValue(user, values, 'rg')}
                            sx={{
                              background: '#ffffff',
                            }}
                            disabled={confirmedUserData}
                          />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          {isUserVerified ? (
                            <InputMask
                              mask='999.999.999-99'
                              value={updateInputValue(user, values, 'cpf')}
                              onChange={handleChange}
                              disabledUnderline={true}
                              disabled={true}
                            >
                              {(inputProps) => (
                                <JumboTextField
                                  {...inputProps}
                                  id='cpf'
                                  fullWidth
                                  name='cpf'
                                  label='CPF'
                                  type='text'
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                  disabled={inputProps.disabledUnderline}
                                />
                              )}
                            </InputMask>
                          ) : (
                            <InputMask
                              mask='999.999.999-99'
                              value={updateInputValue(user, values, 'cpf')}
                              onChange={handleChange}
                              disabledUnderline={confirmedUserData}
                              disabled={confirmedUserData}
                            >
                              {(inputProps) => (
                                <JumboTextField
                                  {...inputProps}
                                  id='cpf'
                                  fullWidth
                                  name='cpf'
                                  label='CPF'
                                  type='text'
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                  disabled={inputProps.disabledUnderline}
                                />
                              )}
                            </InputMask>
                          )}
                        </Grid>

                        <Grid item lg={6} xs={12}>
                          {verifyMask /* ||
                          updateInputValue(user, values, 'celular').replace(
                            /[^\d]/g,
                            ''
                          ).length === 14 */ ? (
                            <InputMask
                              mask={
                                verifyMask ? '+999(99)99999-9999' : undefined
                              }
                              maskPlaceholder={null}
                              error={
                                touched.celular ? errors.celular : undefined
                              }
                              touched={touched.celular}
                              value={updateInputValue(user, values, 'celular')}
                              onChange={handleChange}
                              disabled={confirmedUserData}
                              disabledUnderline={confirmedUserData}
                            >
                              {(inputProps) => (
                                <JumboTextField
                                  {...inputProps}
                                  fullWidth
                                  name='celular'
                                  label='Celular (DDI, DDD e número)'
                                  sx={{ background: '#ffffff' }}
                                  disabled={inputProps.disabledUnderline}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            </InputMask>
                          ) : (
                            <JumboTextField
                              fullWidth
                              name='celular'
                              label='Celular (DDI, DDD e número)'
                              sx={{ background: '#ffffff' }}
                              InputLabelProps={{ shrink: true }}
                              value={updateInputValue(
                                user,
                                values,
                                'celular'
                              ).replace(/[^\d]/g, '')}
                              onChange={handleChange}
                              onBlur={() => setFieldTouched('celular', true)}
                              inputRef={celularInputRef}
                              disabled={confirmedUserData}
                            />
                          )}
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          {verifyMask /* ||
                          updateInputValue(
                            user,
                            values,
                            'celular_confirmacao'
                          ).replace(/[^\d]/g, '').length === 14 */ ? (
                            <InputMask
                              mask={
                                verifyMask ? '+999(99)99999-9999' : undefined
                              }
                              maskPlaceholder={null}
                              value={updateInputValue(
                                user,
                                values,
                                'celular_confirmacao'
                              )}
                              onChange={handleChange}
                              onPaste={(e) => e.preventDefault()}
                              error={
                                touched.celular_confirmacao
                                  ? errors.celular_confirmacao
                                  : undefined
                              }
                              touched={touched.celular_confirmacao}
                              // onBlur={() => setTouched({'celular_confirmacao': true})}
                              onBlur={() =>
                                setFieldTouched('celular_confirmacao', true)
                              }
                              disabled={confirmedUserData}
                              disabledUnderline={confirmedUserData}
                            >
                              {(inputProps) => (
                                <JumboTextField
                                  {...inputProps}
                                  fullWidth
                                  name='celular_confirmacao'
                                  label='Confirmar celular (DDI, DDD e número)'
                                  sx={{ background: '#ffffff' }}
                                  disabled={inputProps.disabledUnderline}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            </InputMask>
                          ) : (
                            <JumboTextField
                              fullWidth
                              name='celular_confirmacao'
                              label='Confirmar celular (DDI, DDD e número)'
                              sx={{ background: '#ffffff' }}
                              InputLabelProps={{ shrink: true }}
                              value={updateInputValue(
                                user,
                                values,
                                'celular_confirmacao'
                              ).replace(/[^\d]/g, '')}
                              onChange={handleChange}
                              onBlur={() =>
                                setFieldTouched('celular_confirmacao', true)
                              }
                              disabled={confirmedUserData}
                            />
                          )}
                        </Grid>

                        <Grid item lg={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name='email'
                            label='Email'
                            type='email'
                            value={updateInputValue(user, values, 'email')}
                            sx={{
                              background: '#ffffff',
                            }}
                            error={touched.email ? errors.email : undefined}
                            touched={+touched.email}
                            disabled={confirmedUserData}
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <JumboTextField
                            fullWidth
                            name='email_confirmacao'
                            label='Confirmar email'
                            type='email'
                            value={updateInputValue(
                              user,
                              values,
                              'email_confirmacao'
                            )}
                            sx={{
                              background: '#ffffff',
                            }}
                            disabled={confirmedUserData}
                            onPaste={(e) => e.preventDefault()}
                            onChange={handleChange}
                            error={
                              touched.email_confirmacao
                                ? errors.email_confirmacao
                                : undefined
                            }
                            touched={+touched.email_confirmacao}
                          />
                        </Grid>

                        {/* {isNewUser ? ( */}
                        <>
                          <Grid item lg={lg ? 4 : 4} xs={12}>
                            <FormControl fullWidth>
                              <Select
                                options={COUNTRIES}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                onChange={(pais) =>
                                  setFieldValue('pais', pais.value)
                                }
                                value={COUNTRIES.filter(
                                  (option) => option.value === values.pais
                                )}
                                placeholder='País'
                                isDisabled={confirmedUserData}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={lg ? 8 : 8} xs={12}>
                            <Typography variant='h6' component='h6'>
                              Escolha no campo de seleção{' '}
                              {lg ? 'abaixo' : 'ao lado'} o País em que você
                              mora atualmente
                            </Typography>
                          </Grid>
                          {values.pais === 'BR' ? (
                            <>
                              <Grid item lg={2} xs={10}>
                                <InputMask
                                  mask='99999-999'
                                  value={updateInputValue(user, values, 'CEP')}
                                  onChange={handleChange}
                                  onBlur={(e) => checkCep(e, setFieldValue)}
                                  disabled={confirmedUserData}
                                  disabledUnderline={confirmedUserData}
                                >
                                  {(inputProps) => (
                                    <JumboTextField
                                      {...inputProps}
                                      fullWidth
                                      name='CEP'
                                      label='CEP'
                                      sx={{ background: '#ffffff' }}
                                      disabled={inputProps.disabledUnderline}
                                    />
                                  )}
                                </InputMask>
                              </Grid>
                              <Grid item lg={1} xs={1}>
                                {isLoading && (
                                  <CircularProgress
                                    style={{ alignSelf: 'center' }}
                                  />
                                )}
                              </Grid>
                              <Grid item lg={7} xs={12}>
                                <JumboTextField
                                  fullWidth
                                  name='endereco'
                                  label='Endereço'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'endereco'
                                  )}
                                  sx={{ background: '#ffffff' }}
                                  disabled={confirmedUserData}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item lg={2} xs={4}>
                                <JumboTextField
                                  fullWidth
                                  name='numero'
                                  label='Número'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'numero'
                                  )}
                                  sx={{ background: '#ffffff' }}
                                  disabled={confirmedUserData}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item lg={3} xs={8}>
                                <JumboTextField
                                  fullWidth
                                  name='complemento'
                                  label='Complemento'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'complemento'
                                  )}
                                  sx={{ background: '#ffffff' }}
                                  disabled={confirmedUserData}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item lg={2} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='bairro'
                                  label='Bairro'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'bairro'
                                  )}
                                  sx={{ background: '#ffffff' }}
                                  disabled={confirmedUserData}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>

                              <Grid item lg={2} xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id='uf'>UF</InputLabel>
                                  <JumboSelectField
                                    labelId='uf'
                                    id='uf'
                                    name='uf'
                                    value={updateInputValue(user, values, 'uf')}
                                    label='UF'
                                    sx={{ background: '#ffffff' }}
                                    disabled={confirmedUserData}
                                  >
                                    <MenuItem value={'AC'}>Acre</MenuItem>
                                    <MenuItem value={'AL'}>Alagoas</MenuItem>
                                    <MenuItem value={'AP'}>Amapá</MenuItem>
                                    <MenuItem value={'AM'}>Amazonas</MenuItem>
                                    <MenuItem value={'BA'}>Bahia</MenuItem>
                                    <MenuItem value={'CE'}>Ceará</MenuItem>
                                    <MenuItem value={'DF'}>
                                      Distrito Federal
                                    </MenuItem>
                                    <MenuItem value={'ES'}>
                                      Espírito Santo
                                    </MenuItem>
                                    <MenuItem value={'GO'}>Goiás</MenuItem>
                                    <MenuItem value={'MA'}>Maranhão</MenuItem>
                                    <MenuItem value={'MT'}>
                                      Mato Grosso
                                    </MenuItem>
                                    <MenuItem value={'MS'}>
                                      Mato Grosso do Sul
                                    </MenuItem>
                                    <MenuItem value={'MG'}>
                                      Minas Gerais
                                    </MenuItem>
                                    <MenuItem value={'PA'}>Pará</MenuItem>
                                    <MenuItem value={'PB'}>Paraíba</MenuItem>
                                    <MenuItem value={'PR'}>Paraná</MenuItem>
                                    <MenuItem value={'PE'}>Pernambuco</MenuItem>
                                    <MenuItem value={'PI'}>Piauí</MenuItem>
                                    <MenuItem value={'RJ'}>
                                      Rio de Janeiro
                                    </MenuItem>
                                    <MenuItem value={'RN'}>
                                      Rio Grande do Norte
                                    </MenuItem>
                                    <MenuItem value={'RS'}>
                                      Rio Grande do Sul
                                    </MenuItem>
                                    <MenuItem value={'RO'}>Rondônia</MenuItem>
                                    <MenuItem value={'RR'}>Roraima</MenuItem>
                                    <MenuItem value={'SC'}>
                                      Santa Catarina
                                    </MenuItem>
                                    <MenuItem value={'SP'}>São Paulo</MenuItem>
                                    <MenuItem value={'SE'}>Sergipe</MenuItem>
                                    <MenuItem value={'TO'}>Tocantins</MenuItem>
                                  </JumboSelectField>
                                </FormControl>
                              </Grid>
                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='cidade'
                                  label='Cidade'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'cidade'
                                  )}
                                  sx={{ background: '#ffffff' }}
                                  disabled={confirmedUserData}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <JumboTextField
                                  fullWidth
                                  name='endereco'
                                  label='Endereço'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'endereco'
                                  )}
                                  sx={{ background: '#ffffff' }}
                                  disabled={confirmedUserData}
                                />
                              </Grid>
                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='codigo_postal'
                                  label='Código Postal'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'codigo_postal'
                                  )}
                                  sx={{ background: '#ffffff' }}
                                  disabled={confirmedUserData}
                                />
                              </Grid>
                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='estado'
                                  label='Estado'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'estado'
                                  )}
                                  sx={{ background: '#ffffff' }}
                                  disabled={confirmedUserData}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                        {/*  ) : undefined} */}
                        {(isNewUser || !userHasPassword) && (
                          <>
                            {!userHasPassword && (
                              <Grid item xs={12}>
                                <Typography variant='h5'>
                                  Crie abaixo uma senha definitiva:
                                </Typography>
                              </Grid>
                            )}
                            <Grid item lg={5} xs={12}>
                              <JumboTextField
                                fullWidth
                                id='senha'
                                type={showPassword ? 'text' : 'password'}
                                name='senha'
                                value={values.senha}
                                disabled={confirmedUserData}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge='end'
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{
                                  background: '#ffffff',
                                }}
                                label='Senha'
                                onBlur={() => setFieldTouched('senha', true)}
                              />
                            </Grid>
                            <Grid item lg={5} xs={12}>
                              <JumboTextField
                                fullWidth
                                id='senhaConfirmacao'
                                type={
                                  showPasswordConfirmation ? 'text' : 'password'
                                }
                                name='senhaConfirmacao'
                                disabled={confirmedUserData}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={
                                          handleClickShowPasswordConfirmation
                                        }
                                        onMouseDown={
                                          handleMouseDownPasswordConfirmation
                                        }
                                        edge='end'
                                      >
                                        {showPasswordConfirmation ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{
                                  background: '#ffffff',
                                }}
                                label='Confirmar nova senha'
                                onBlur={() =>
                                  setFieldTouched('senhaConfirmacao', true)
                                }
                              />
                            </Grid>
                          </>
                        )}

                        {/* <Grid item lg={4} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id='escolaridade'>
                              Nível de escolaridade
                            </InputLabel>
                            <JumboSelectField
                              labelId='escolaridade'
                              id='escolaridade'
                              name='escolaridade'
                              value={updateInputValue(
                                user,
                                values,
                                'escolaridade'
                              )}
                              label='Nível de escolaridade'
                              sx={{
                                background: '#ffffff',
                              }}
                            >
                              <MenuItem value={'nao_estudei'}>
                                Não estudei
                              </MenuItem>
                              <MenuItem value={'primeiro_grau'}>
                                Primeiro grau
                              </MenuItem>
                              <MenuItem value={'segundo_grau'}>
                                Segundo grau
                              </MenuItem>
                              <MenuItem value={'superior'}>Superior</MenuItem>
                              <MenuItem value={'pos_graduado'}>
                                Pós graduado
                              </MenuItem>
                              <MenuItem value={'mestrado'}>Mestrado</MenuItem>
                              <MenuItem value={'doutorado'}>Doutorado</MenuItem>
                              <MenuItem value={'pos_doutorado'}>
                                Pós doutorado
                              </MenuItem>
                            </JumboSelectField>
                          </FormControl>
                        </Grid> */}

                        {/* <Grid item lg={4} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id='religiao'>
                              Religião de origem
                            </InputLabel>
                            <JumboSelectField
                              labelId='religiao'
                              id='religiao'
                              name='religiao'
                              label='Religião de origem'
                              value={updateInputValue(user, values, 'religiao')}
                              sx={{
                                background: '#ffffff',
                              }}
                            >
                              <MenuItem value={'budismo'}>Budismo</MenuItem>
                              <MenuItem value={'catolica'}>Católica</MenuItem>
                              <MenuItem value={'espiritismo'}>
                                Espiritismo
                              </MenuItem>
                              <MenuItem value={'matriz_africana'}>
                                Matriz africana
                              </MenuItem>
                              <MenuItem value={'protestante'}>
                                Protestante
                              </MenuItem>
                              <MenuItem value={'umbanda'}>Umbanda</MenuItem>
                              <MenuItem value={'outra'}>Outra</MenuItem>
                            </JumboSelectField>
                          </FormControl>
                        </Grid> */}
                        {confirmedUserData || isNewUser ? (
                          <>
                            <Grid item xs={12}>
                              {classes[0]?.Curso?.Curso?.length > 0 && (
                                <>
                                  <Typography
                                    variant='h4'
                                    component='div'
                                    fontWeight={800}
                                  >
                                    Para se inscrever neste curso, é necessário
                                    ter concluído o curso:
                                  </Typography>
                                  <List>
                                    {classes[0]?.Curso?.Curso?.map(
                                      (requirement) => (
                                        <ListItem
                                          key={requirement.pre_requisito.id}
                                        >
                                          <ListItemText
                                            primary={
                                              <Typography
                                                sx={{
                                                  color: '#0092D1',
                                                  fontWeight: 800,
                                                  fontSize: 16,
                                                }}
                                              >
                                                {requirement.pre_requisito.nome}
                                              </Typography>
                                            }
                                          />
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                </>
                              )}
                            </Grid>

                            {classes[0]?.Curso?.Curso?.length > 0 &&
                              !user.atendeRequisitos && (
                                <>
                                  <Grid item xs={12}>
                                    <Typography variant='h5'>
                                      Se você é de outra instituição, por favor,
                                      anexe documentos que comprovem a
                                      semelhança entre os cursos que você já
                                      concluiu e os pré-requisitos exigidos.
                                      Caso não consiga anexar os documentos,
                                      envie-os para o e-mail
                                      <strong> ensino@feesp.org.br</strong>.
                                      Esses documentos serão avaliados
                                      posteriormente para validar seu histórico
                                      educacional.
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={2}
                                    >
                                      <Button
                                        variant='contained'
                                        component='label'
                                        color='info'
                                        size='large'
                                        sx={{
                                          fontSize: { xs: '0.7rem' },
                                          textAlign: 'center',
                                        }}
                                      >
                                        Adicionar Declarações/Comprovantes
                                        <input
                                          type='file'
                                          hidden
                                          name='imagem_area'
                                          onChange={uploadHandler}
                                        />
                                      </Button>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12}>
                                    <FileListSlim
                                      files={files}
                                      removeFile={removeFile}
                                      isEditingFile={isEditingFile}
                                      textFile={textFile}
                                      handleInputChangeFile={
                                        handleInputChangeFile
                                      }
                                      handleSaveClick={handleSaveClick}
                                      handleEditClick={handleEditClick}
                                      setIsEditingFile={setIsEditingFile}
                                    />
                                  </Grid>
                                </>
                              )}

                            <Grid item xs={12}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: texts?.texto_complementar,
                                }}
                              />
                              <FormControl>
                                <FormLabel
                                  id='horario-label'
                                  sx={{ fontSize: '1.3rem', color: '#7352c7' }}
                                >
                                  Escolha uma das turmas disponíveis:
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby='horario-label'
                                  name='turmas_id'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'turmas_id'
                                  )}
                                  onChange={handleChange}
                                >
                                  {filteredGroups.map(([category, classes]) => (
                                    <>
                                      <Typography
                                        variant='h6'
                                        sx={{
                                          fontWeight: 'bold',
                                          my: 1,
                                          color: '#7352c7',
                                          fontSize: '1.1rem',
                                        }}
                                      >
                                        {/* Presenciais */}
                                        {category.includes(
                                          'Presencial na CASA TRANSITÓRIA'
                                        ) && (
                                          <>
                                            Presencial na {''}
                                            <span
                                              style={{
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              CASA TRANSITÓRIA
                                            </span>
                                            <span style={{ color: '#37373C' }}>
                                              {''} (Av. Condessa Elizabeth de
                                              Robiano – 454 – Belenzinho – SP):
                                            </span>
                                          </>
                                        )}

                                        {category.includes(
                                          'Presencial na SEDE MARIA PAULA'
                                        ) && (
                                          <>
                                            Presencial na {''}
                                            <span
                                              style={{
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              SEDE MARIA PAULA
                                            </span>
                                            <span style={{ color: '#37373C' }}>
                                              {''} (Rua Maria Paula, 140,
                                              Centro, SP):
                                            </span>
                                          </>
                                        )}

                                        {category.includes(
                                          'Presencial na SEDE SANTO AMARO'
                                        ) && (
                                          <>
                                            Presencial na {''}
                                            <span
                                              style={{
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              SEDE SANTO AMARO
                                            </span>
                                            <span style={{ color: '#37373C' }}>
                                              {''} (Rua Santo Amaro, 370 – Bela
                                              Vista térreo):
                                            </span>
                                          </>
                                        )}
                                        {/* Hibridos */}
                                        {category.includes(
                                          'Híbrido na CASA TRANSITÓRIA'
                                        ) && (
                                          <>
                                            Híbrido na {''}
                                            <span
                                              style={{
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              CASA TRANSITÓRIA
                                            </span>
                                            <span style={{ color: '#37373C' }}>
                                              {''} (Av. Condessa Elizabeth de
                                              Robiano – 454 – Belenzinho – SP):
                                            </span>
                                          </>
                                        )}
                                        {category.includes(
                                          'Híbrido na SEDE MARIA PAULA'
                                        ) && (
                                          <>
                                            Híbrido na {''}
                                            <span
                                              style={{
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              SEDE MARIA PAULA
                                            </span>
                                            <span style={{ color: '#37373C' }}>
                                              {''} (Rua Maria Paula, 140,
                                              Centro, SP):
                                            </span>
                                          </>
                                        )}
                                        {category.includes(
                                          'Híbrido na SEDE SANTO AMARO'
                                        ) && (
                                          <>
                                            Híbrido na {''}
                                            <span
                                              style={{
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              SEDE SANTO AMARO
                                            </span>
                                            <span style={{ color: '#37373C' }}>
                                              {''} (Rua Santo Amaro, 370 – Bela
                                              Vista térreo):
                                            </span>
                                          </>
                                        )}

                                        {category === 'ON-LINE' && (
                                          <>
                                            ON-LINE
                                            <span style={{ color: '#37373C' }}>
                                              {''} (Virtual pelo Google Meet):
                                            </span>
                                          </>
                                        )}
                                      </Typography>

                                      {classes.map((classe) => (
                                        <FormControlLabel
                                          key={classe.turmas_id}
                                          value={classe.turmas_id}
                                          control={<Radio size='small' />}
                                          sx={{
                                            alignItems: lg
                                              ? 'flex-start'
                                              : 'center',
                                            mb: lg ? '1rem' : '0',
                                          }}
                                          label={
                                            <Typography
                                              variant='body1'
                                              sx={{
                                                /* fontWeight: 'bold', */
                                                fontSize: '1.1rem',
                                                mt: lg ? '6px' : '0',
                                              }}
                                            >
                                              {`${classe.dia_da_semana} - ${classe.horario}`}
                                            </Typography>
                                          }
                                        />
                                      ))}
                                    </>
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sx={{ textAlign: 'center', mt: '1rem' }}
                            >
                              <LoadingButton
                                color='success'
                                type='submit'
                                variant='contained'
                                size='large'
                                sx={{ maxWidth: { md: '200px' }, mt: '1rem' }}
                                loading={isSubmitting}
                                disabled={disableSubmitButton || isSubmitting}
                              >
                                Enviar
                              </LoadingButton>
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            sx={{ textAlign: 'center', mt: '1rem' }}
                          >
                            {/*  <Button
                              color='success'
                              variant='contained'
                              size='large'
                              onClick={() => modalConfirmedAlert(values, setFieldError)}
                            >
                              Sim, estão corretos
                            </Button> */}
                            <LoadingButton
                              color='success'
                              type='submit'
                              variant='contained'
                              size='large'
                              loading={isSubmitting}
                            >
                              Clique para atualizar e continuar
                            </LoadingButton>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>

                {isSubmitting /* && Object.keys(errors).length !== 0 */
                  ? errorsHandler(errors)
                  : null}
                {console.log(errors)}

                {verifyValidations(values)}
                {localStorage.setItem('userPendent', JSON.stringify(values))}
              </Form>
            )}
          </Formik>
        </Paper>
      )}
    </JumboContentLayout>
  );
};

export default JoinCoursesIntroForm;
