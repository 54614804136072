/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import {
  Accordion,
  Card,
  Tooltip,
  Typography,
  Zoom,
  Grid,
  MenuItem,
  useTheme,
  useMediaQuery,
  Badge,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import capitalize from '../../../utils/capitalize';
import formatDate from '../../../utils/formatDate';
import { DAYS_OF_WEEK } from '../../../utils/constants/daysOfWeek';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { TextService } from 'app/servicesTwo/TextService';
import { Link, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { useEffect } from 'react';
import { EllipsisTooltip } from 'app/components/EllipsisTooltip/EllipsisTooltip';
import { IconButton } from '@mui/material';
import { Menu } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(CSVLink)`
  padding: 4px 10px;
  background: #0092d0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    color: white;
  }
`;

const ClassItem = ({ item, setClasses, classes, select1Value = null, searchTerm = null, searchName = null, page = 1 }) => {
  const { hasPermission } = useContext(PermissionContext);
  const location = useLocation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xl'));
  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const modalAlert = (id) => {
    if (item.Curso_Historico.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: `<strong style="color:red">Não é possível excluir a turma porque há alunos associados.`,
      });
    } else {
      Swal.fire({
        title: 'Tem certeza que deseja apagar?',
        text: 'Não será póssível reverter a ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Não!',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          deleteClass(id);
        }
      });
    }
  };

  function removeById(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.turmas_id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }

    return arr;
  }

  const deleteClass = useCallback(async (id) => {
    try {
      ApiService.delete(`/turmas/${id}`)
        .then((response) => {
          toast('success', 'Removido com sucesso');
          const updatedClassesArray = removeById(classes, id);
          setClasses(updatedClassesArray);
        })
        .catch((error) => {
          if (error.response) {
            toast('error', error.response.data.error);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
      toast('error', 'Ocorreu um erro');
    }
  }, []);

  const verifyCourse = () => {
    if (item.Curso.deletedAt !== null) {
      return 'Curso excluído';
    }
    return item.Curso?.nome;
  };

  const textRef = useRef(null);

  /* const data = [];
    const alunos = item.Curso_Historico?.map(i => ({
        nome_do_aluno: i.User.nome, 
        cpf: i.User.cpf,
        status: i.status,
        motivo_reprovacao: i.motivo_reprovacao,
        observacao: i.observacao
    })) || [];
    const addedNames = {}; // objeto para armazenar nomes já adicionados
    
    if (alunos.length === 0) {
        const newRow = {
            ...item,
            nome_do_curso: item.Curso.nome,
            nome_do_aluno: "",
            cpf: "",
            status: "",
            motivo_reprovacao: "",
            observacao: ""
        };
        data.push(newRow);
    }
    
    for (const aluno of alunos) {
        const newRow = {
            ...item,
            nome_do_curso: item.Curso.nome,
            nome_do_aluno: aluno.nome_do_aluno,
            cpf: aluno.cpf,
            status: aluno.status,
            motivo_reprovacao: aluno.motivo_reprovacao,
            observacao: aluno.observacao
        };
        if(!addedNames[aluno.nome_do_aluno]) { // verifica se o nome já foi adicionado antes
            data.push(newRow);
            addedNames[aluno.nome_do_aluno] = true; // marca o nome como adicionado
        }
    }
    

    const generateOptions = (key) => ({ label: key, key });

    const headers = [
        // para turmas
        generateOptions('ano'),
        generateOptions('nome_do_curso'),
        generateOptions('dia_da_semana'),
        generateOptions('horario'),
        generateOptions('modo'),
        generateOptions('local'),
        generateOptions('grupo'),

        // para alunos
        generateOptions('nome_do_aluno'),
        generateOptions('cpf'),
        generateOptions('status'),
        generateOptions('motivo_reprovacao'),
        generateOptions('observacao')
    ]; */

  const maxLengthText = 8;
  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 0.5,
            flexDirection: 'row-reverse',

            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',

              '&.Mui-expanded': {
                margin: '12px 0',
              },
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              borderRadius: 1,
              border: 1,
              color: 'text.secondary',
              borderColor: 'divider',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main',
              },

              '& svg': {
                fontSize: '1.25rem',
              },
            },
          }}
        >
          {/* <Grid container spacing={2} alignContent={'center'}> */}
          <Div
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'none',
                lg: 'block',
              },
              width: { xs: '7%', lg: '7%' },
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Ano
            </Typography>
            <Typography variant={'body1'}>{item.ano}</Typography>
          </Div>
          <Div
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'none',
                lg: 'block',
              },
              width: { xs: '22%', xl: '22%' },
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Nome do curso
            </Typography>
            <EllipsisTooltip text={verifyCourse()} />
          </Div>
          <Div
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'none',
                lg: 'block',
              },
              width: { xs: '10%', xl: '10%' },
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Dia
            </Typography>
            <EllipsisTooltip text={item.dia_da_semana} />
          </Div>
          <Div
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'none',
                lg: 'block',
              },
              width: { xs: '10%', xl: '8%' },
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Horário
            </Typography>
            <Typography variant={'body1'}>{item.horario}</Typography>
          </Div>
          <Div
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'none',
                lg: 'block',
              },
              width: { xs: '12%', xl: '10%' },
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Modo
            </Typography>
            <Typography
              variant={'body1'}
              sx={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              { item.modo === 'EAD' ? 'On-line' : item.modo }
            </Typography>
          </Div>
          <Div
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'none',
                lg: 'block',
              },
              width: { xs: '10%', xl: '12%' },
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Local
            </Typography>
            {/*  {item.local.length > maxLengthText ? (
                            <Tooltip
                                title={item.local}
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <Typography variant={'body1'}>
                                    {TextService.limitText(
                                        capitalize(item.local),
                                        maxLengthText
                                    )}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography variant={'body1'}>
                                {capitalize(item.local)}
                            </Typography>
                        )} */}

            <EllipsisTooltip text={ 
                item.local === 'Sede' ? 
                'Sede Maria Paula' : 
                item.local === 'sede_santo_amaro' ?
                'Sede Santo Amaro' :
                item.local
              } 
            />
          </Div>

          <Div
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'none',
                lg: 'block',
              },
              width: { xs: '16%', xl: '14%' },
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Periodicidade
            </Typography>
            <EllipsisTooltip text={capitalize(item.periodicidade)} />
          </Div>

          <Div
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'none',
                lg: 'block',
              },
              width: { xs: '7%', xl: '7%' },
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              Grupo
            </Typography>
            <Typography variant={'body1'}>{item.grupo}</Typography>
          </Div>
          <Div
            sx={{
              display: 'flex',
              lineBreak: 'anywhere',
              flexShrink: 1,
              px: 1,
            }}
          >
            <>
              <Tooltip
                title={
                  location.pathname.includes('inserir')
                    ? 'Clique para adicionar alunos em turmas' 
                    : location.pathname.includes('transferir')
                    ? 'Clique para atualizar status ou transferir alunos'
                    : 'Visualizar'
                }
                TransitionComponent={Zoom}
                arrow
              >
                <Item>
                  <Badge 
                    color="info" 
                    badgeContent={item.Curso_Historico.length > 0 ? item.Curso_Historico.length : 0}
                    max={999}
                    showZero
                    invisible={!location.pathname.includes('inserir') && !location.pathname.includes('transferir') ? true : false}
                    title='Quantidade de alunos'
                  >
                    <LinkStyle
                      to={
                        location.pathname.includes('inserir')
                          ? `/app/inserir-aluno-turma/${item.turmas_id}`
                          : location.pathname.includes('transferir')
                          ? `/app/listar-atualizar-status-transferir-aluno/${item.turmas_id}`
                          : `/app/editar-turma/${item.turmas_id}`
                      }
                      state={{
                        backUrl: location.pathname,
                        filters: { searchName, searchTerm, select1Value, page },
                      }}
                    >
                      {location.pathname.includes('inserir') || location.pathname.includes('transferir') ? (
                        <PersonAddIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </LinkStyle>
                  </Badge>
                </Item>
              </Tooltip>

              <Tooltip title='Excluir' TransitionComponent={Zoom} arrow>
                <Item>
                  <Button
                    sx={{
                      minWidth: 1,
                      background:
                        item.Curso_Historico.length > 0
                          ? 'rgba(0, 0, 0, 0.12)'
                          : '',
                    }}
                    disableElevation
                    variant={'contained'}
                    size={'small'}
                    color={'secondary'}
                    disabled={!hasPermission('Turmas', 'deletar')}
                    onClick={() => modalAlert(item.turmas_id)}
                  >
                    <DeleteIcon />
                  </Button>
                </Item>
              </Tooltip>
            </>
          </Div>
          {/*  </Grid> */}
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};
/* Todo item prop define */
export default ClassItem;
