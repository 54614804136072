import {config} from "../../../app/config/main";

export const storeToken = (token, userId, username, userEmail, userImage, storedPermissions, role) => {
    localStorage.setItem('token', token);
    localStorage.setItem('storedUserId', userId);
    localStorage.setItem('storedUserEmail', userEmail);
    localStorage.setItem('storedUserImage', userImage);
    localStorage.setItem('storedUsername', username);
    localStorage.setItem('storedPermissions', JSON.stringify(storedPermissions));
    localStorage.setItem('role', role);
    if(!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        config.authSetting.axiosObject.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('storedUserId');
    localStorage.removeItem('storedUsername');
    localStorage.removeItem('storedUserEmail');
    localStorage.removeItem('storedUserImage');
    localStorage.removeItem('storedPermissions');
    localStorage.removeItem('role');
    if(!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        delete config.authSetting.axiosObject.defaults.headers.common['Authorization'];
};