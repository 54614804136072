import { useState, useEffect, useContext, createContext } from 'react'
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

export const PermissionContext = createContext();


export const PermissionProvider = ({children}) => {
    const { userPermissions, authUser, role } = useJumboAuth();
    console.log({authUser})
    console.log({userPermissions})
    console.log({role})
     
    /* const hasPermission = (permission, action) => {
        const permissionObj = userPermissions?.find((p) => p.tela.nome === permission);
        return permissionObj && permissionObj[action];
    }; */

    const hasPermission = (permission, action) => {
      let hasPermission = false;
      userPermissions?.forEach((permissionObj) => {
        if (permissionObj.tela.nome === permission && permissionObj[action]) {
          hasPermission = true;
        }
      });
      return hasPermission;
    };
    


    return (
        <PermissionContext.Provider value={{ hasPermission, userRole: role }}>
          {children}
        </PermissionContext.Provider>
      );
}
