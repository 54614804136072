import { PermissionContext } from "app/contexts/PermissionContext";
import { Navigate, Route } from "react-router-dom";
import { useContext } from "react";
import Page from "@jumbo/shared/Page";

function ProtectedRoute({permission, requiredRole, component, allowAluno = false }) {
    const { hasPermission, userRole } = useContext(PermissionContext);

    if (allowAluno && userRole === 'aluno') {
      // Aluno tem acesso, retorna a página diretamente
      return <Page component={component} />;
    }
  
    if (!hasPermission(permission, requiredRole)) {
      return <Navigate to="/app" />;
    }
  
    return <Page component={component} />;
  }
  
  export default ProtectedRoute;
