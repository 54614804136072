import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  TableCell,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import capitalize from 'app/utils/capitalize';

const columnsRegularActive = [
  {
    name: 'Turma.ano',
    label: 'Ano',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma.Curso.nome',
    label: 'Nome',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({
        align: 'left',
        width: '40%',
      }),
      setCellProps: () => ({
        align: 'left',
        width: '40%',
      }),
      customHeadRender: (
        columnMeta,
        handleToggleColumn,
        sortOrder,
        sortColumn
      ) => (
        <TableCell width={'40%'} align='left'>
          Nome
        </TableCell>
      ),
      customBodyRender: (value) => (
        <Typography variant={'body1'}>{value}</Typography>
      ),
    },
  },
  {
    name: 'Turma.dia_da_semana',
    label: 'Dia da semana',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma.horario',
    label: 'Horário',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
  {
    name: 'Turma.local',
    label: 'Local',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>
            {capitalize(
              value === 'Sede'
                ? 'Sede Maria Paula'
                : value === 'sede_santo_amaro'
                ? 'Sede Santo Amaro'
                : value
            )}
          </Typography>
        </>
      ),
    },
  },

  {
    name: 'status',
    label: 'Status',
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: (value) => ({
        align: 'center',
      }),
      setCellProps: () => ({
        align: 'center',
      }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <>
          <Typography variant={'body1'}>{value ?? '-'}</Typography>
        </>
      ),
    },
  },
];

const optionsRegularActive = {
  pagination: false,
  filterType: 'checkbox',
  selectableRows: 'none',
  search: false,
  download: false,
  print: false,
  sortFilterList: false,
  viewColumns: false,
  filter: false,
  enableNestedDataAccess: '.',
  textLabels: {
    pagination: {
      next: 'Próxima Página',
      previous: 'Página Anterior',
      rowsPerPage: 'Itens por Página:',
      displayRows: 'de',
    },
  },
  setTableProps: () => {
    return {
      size: 'small',
    };
  },
};

const componentsRegularActive = {
  ExpandButton: function (props) {
    return <div style={{ width: '24px' }} />;
  },
};

const CurrentCoursesAccordion = ({ courseHistoryOnlyActive }) => {
  console.log({ courseHistoryOnlyActive });
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={1}
      marginTop='2rem'
      marginBottom='1rem'
    >
      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={null}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
          >
            <Typography variant={'h4'}>Cursos Atuais</Typography>
            <Box display={'flex'} alignItems={'center'}>
              <Typography>Ocultar</Typography>
              <Switch
                checked={expanded}
                onChange={handleChange}
                name='toggleAccordion'
                inputProps={{
                  'aria-label': 'toggle accordion',
                }}
              />
              <Typography>Visualizar</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {courseHistoryOnlyActive.length > 0 ? (
            <MUIDataTable
              data={courseHistoryOnlyActive}
              columns={columnsRegularActive}
              options={optionsRegularActive}
              components={componentsRegularActive}
            />
          ) : (
            <Typography>Nenhum curso encontrado</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CurrentCoursesAccordion;
