import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useContext,
} from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import PageHeader from '../../../layouts/shared/headers/PageHeader/PageHeader';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  Typography,
  Button,
  Box,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { ApiService } from 'app/servicesTwo/ApiService';
import ClassItem from './ClassItem';
import JumboSearch from '@jumbo/components/JumboSearch';
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate } from 'react-router-dom';
import JumboSearchWithoutDebounce from '@jumbo/components/JumboSearchWithoutDebounce';

const validationSchema = yup.object({
  email: yup.string('Insira seu email').email('Insira um email válido'),
  senha: yup.string('Insira sua senha'),
  nome: yup.string('Insira seu nome').required('Preenchimento obrigatório'),
  cpf: yup.string(),
  rg: yup.string().max(11, 'Deve ter no máximo 11 digitos'),
  data_nascimento: yup
    .date()
    .max(new Date(), 'Não é possivel incluir uma data futura')
    .required('Preenchimento obrigatório'),
  celular: yup.string(),
});

const ClassStudentsStatusOrTransferList = () => {
  const { theme } = useJumboTheme();
  const animatedComponents = makeAnimated();
  const location = useLocation();
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const { hasPermission } = useContext(PermissionContext);

  const [classes, setClasses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(location?.state?.page ?? 1);
  const [per_page, setPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const [select1Value, setSelect1Value] = useState(
    location?.state?.select1Value
      ? JSON.stringify(location?.state?.select1Value)
      : ''
  );
  const [searchName, setSearchName] = useState(
    location?.state?.searchName ?? ''
  );
  const [searchTerm, setSearchTerm] = useState(
    location?.state?.searchTerm ?? ''
  );

  const navigate = useNavigate();

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const getClasses = useCallback(async (searchData = {}) => {
    setLoading(true);

    const {
      search = '',
      page = 1,
      per_page = 10,
      name = '',
      selectedValueToString = '',
    } = searchData;

    try {
      const response = await ApiService.get('/turmas', {
        params: { search, page, per_page, name, selectedValueToString },
      });
      setClasses(response.data.classes);
      setCount(response.data.total_pages);
    } catch (error) {
      console.error('Error fetching classes:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    Swal.close();
  }, [location.pathname, Swal]);

  const handleSelect1Change = (selected) => {
    setSelect1Value(selected?.length === 0 ? '' : selected);
  };

  const optionsYears = () => {
    const startYear = 1980;
    const endYear = new Date().getFullYear();
    return Array.from({ length: endYear - startYear + 3 }, (_, i) => ({
      value: endYear + 2 - i,
      label: (endYear + 2 - i).toString(),
    }));
  };

  const handleSubmit = () => {
    const selectedValueToString = JSON.stringify(select1Value);

    const searchData = {
      search: searchTerm,
      page,
      per_page,
      selectedValueToString,
      name: searchName,
    };
    setLoading(true);
    getClasses(searchData);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      handleSubmit(searchName, searchTerm, select1Value);
    }
  };

  useEffect(() => {
    if (location.state) {
      const { searchName, searchTerm, select1Value } = location.state;

      // Atualize os estados apenas uma vez
      setSearchName((prev) => (prev !== searchName ? searchName || '' : prev));
      setSearchTerm((prev) => (prev !== searchTerm ? searchTerm || '' : prev));
      const selectedValueToString = JSON.stringify(select1Value);
      handleSelect1Change(select1Value);
      setPage((prev) =>
        prev !== location.state.page ? location.state.page || 1 : prev
      );

      getClasses({
        search: searchTerm || '',
        page: page,
        per_page,
        name: searchName || '',
        selectedValueToString,
      });
    } else {
      getClasses({ page, per_page });
    }
  }, [location.state, getClasses, page, per_page]);

  useEffect(() => {
    return () => {
      if (location?.state?.filters) {
        navigate(location.pathname, { replace: true });
      }
    };
  }, [location.state, location.pathname, navigate]);

  console.log(location.pathname);
  useEffect(() => {
    setSelect1Value('');
    setSearchName('');
    setSearchTerm('');
  }, [location.pathname]);

  return (
    <JumboContentLayout
      header={<PageHeader title={'Atualizar Status ou Transferir Aluno'} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '40px' }}>
        <Box
          display='flex'
          alignItems='center'
          gap='1rem'
          marginBottom='1rem'
          flexWrap='wrap'
          onKeyDown={(e) => {
            handleKeyDown(e, searchName, searchTerm, select1Value);
          }}
        >
          <Box display='flex' alignItems='center' gap='1rem'>
            <Select
              id='select-one'
              isMulti
              options={optionsYears()}
              value={select1Value}
              onChange={handleSelect1Change}
              components={animatedComponents}
              placeholder='Selecione ano'
              styles={{
                menu: (list) => ({
                  ...list,
                  zIndex: 3,
                }),
                control: (base) => ({
                  ...base,
                  width: '100%',
                }),
              }}
            />
          </Box>

          <Box display='flex' alignItems='center' gap='1rem'>
            <JumboSearchWithoutDebounce
              defaultValue={searchName}
              onChange={(e) => setSearchName(e)}
              placeholder='Nome do curso'
            />
          </Box>

          <Box display='flex' alignItems='center' gap='1rem'>
            <Button variant={'contained'} onClick={handleSubmit}>
              Filtrar
            </Button>
          </Box>

          <Box display='flex' alignItems='center' width='40%'>
            <JumboSearchWithoutDebounce
              defaultValue={searchTerm}
              onChange={(e) => setSearchTerm(e)}
              placeholder='Digite o dia da semana, horário, modo, periodicidade ou local aqui...'
            />
          </Box>
        </Box>

        <Fragment>
          {loading ? (
            <Box display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {classes &&
                classes.map((user, key) => (
                  <ClassItem
                    item={user}
                    setClasses={(data) => setClasses([...data])}
                    classes={classes}
                    key={key}
                    page={page}
                    searchName={searchName}
                    searchTerm={searchTerm}
                    select1Value={select1Value}
                  />
                ))}
              {classes && classes.length === 0 && (
                <Typography>Sem resultados correspondentes</Typography>
              )}
            </>
          )}
        </Fragment>
        <Pagination
          sx={{ mt: 2 }}
          color='primary'
          count={count}
          page={page}
          onChange={(event, value) => {
            setPage(value);

            // Atualize o estado da URL para incluir os filtros e a nova página
            navigate(location.pathname, {
              replace: true,
              state: {
                ...location.state,
                page: value,
                searchName,
                searchTerm,
                select1Value,
              },
            });

            // Recarregue os dados com os filtros e a nova página
            const selectedValueToString = JSON.stringify(select1Value);
            getClasses({
              search: searchTerm,
              page: value,
              per_page,
              selectedValueToString,
              name: searchName,
            });
          }}
        />
      </Paper>
    </JumboContentLayout>
  );
};

export default ClassStudentsStatusOrTransferList;
